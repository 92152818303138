<template>
    <v-card
            class="my-auto cb-login-form-container"
            elevation="0"
            height="300"
            style="border-width: 0 !important;"
            tile
            width="400"
    >
        <loading-container
                v-if="loader"
                message="Iniciando sesión y cargando recursos. Por favor espera..."
        />
        <v-card-text>
            <v-row
                    class="ma-0 justify-center"
                    dense
                    style="height: 98px"
            >
                <img
                        alt="Logo"
                        class="my-auto "
                        src="../../assets/cts-logo.png"
                        width="350px"
                />
            </v-row>
            <v-form
                    ref="form"
                    v-model="valid"
                    class="cb-login-form"
                    lazy-validation
            >
                <v-row
                        v-if="mode !== 'expiration'"
                        class="ma-0 justify-center"
                        dense
                >
                    <v-col cols="10">
                        <span class="font-weight-medium">Usuario</span>
                        <v-text-field
                                id="username"
                                v-model="input.username"
                                background-color="white"
                                hide-details
                                name="username"
                                outlined
                                required
                                type="text"
                                @keydown.enter="submit"
                        />
                    </v-col>
                </v-row>

                <v-row
                        class="ma-0 justify-center"
                        dense
                >
                    <v-col cols="10">
                        <span class="font-weight-medium">Contraseña</span>
                        <v-text-field
                                v-model="input.password"
                                autocomplete="new-password"
                                background-color="white"
                                hide-details
                                outlined
                                required
                                type="password"
                                @keydown.enter="submit"
                        />
                    </v-col>
                </v-row>
                <v-row
                        class="ma-0 mt-2 justify-center"
                        dense
                >
                    <v-col cols="10">
                        <v-btn
                                :disabled="helpers.nullOrEmpty(input.username) || helpers.nullOrEmpty(input.password) "
                                :loading="loader"
                                block
                                class="cb-btn-save"
                                color="primary"
                                depressed
                                @click="submit"
                        >
                            {{mode == 'expiration' ? 'Continuar' : 'Iniciar sesión'}}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row
                        class="ma-0 justify-center"
                        dense
                >
                    <v-col
                            v-if="mode !== 'expiration'"
                            cols="10"
                    >
                        <v-row
                                class="ma-0 justify-space-between"
                                dense
                        >
              <span
                      class="font-weight-medium"
                      v-html="environment"
              />
                            <a @click="loginCas()">Login con CAS</a>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row
                        v-if="errorMessage"
                        class="ma-0 my-2 justify-center"
                        dense
                >
                    <v-col cols="10">
                        <v-alert
                                color="error"
                                dense
                                outlined
                        >
                <span class="error--text">
                  <v-icon color="error">warning</v-icon>
                  {{errorMessage}}
                </span>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import service_user from '@/services/service_user'
import vuex_authentication from '@/store/modules/vuex_authentication'
import {mapActions} from 'vuex'
import jwt_decode from 'vue-jwt-decode'
import model_user from '@/constants/models/app_center/model_user'
import helpers from '@/plugins/helpers'
import LoadingContainer from '@/components/repository/LoadingContainer'

export default {
    name: 'LoginForm',
    components: {LoadingContainer},
    props: {
        mode: String
    },
    computed: {
        environment() {
            let url = process.env.VUE_APP_ENDPOINT
            if (url.indexOf('dev') !== -1) {
                return '<span class=\'blue--text environment\' style=\'\'>DESARROLLO</span>'
            } else if (url.indexOf('int') !== -1) {
                return '<span class=\'orange--text environment\' style=\'\'>INTEGRACIÓN</span>'
            } else if (url.indexOf('pre') !== -1) {
                return '<span class=\'green--text environment\' style=\'\'>PRE-PRODUCCIÓN</span>'
            } else if (url.indexOf('testing') !== -1) {
                return '<span class=\'purple--text environment\' style=\'\'>TESTING</span>'
            }
            return '<span class=\'red--text environment\' style=\'\'>PRODUCCIÓN</span>'
        },

        currentUsername() {
            return this.$store.state[vuex_authentication.constants.NAMESPACE][vuex_authentication.constants.S_USERNAME]
        }
    },

    data() {
        return {
            helpers,
            valid: true,
            loader: false,
            errorMessage: '',
            input: {
                username: '',
                password: '',
            }
        }
    },

    methods: {
        ...mapActions({
            setLoginData: `${vuex_authentication.constants.NAMESPACE}/${vuex_authentication.constants.A_SET_LOGIN}`,
        }),
        async submit() {
            const payload = this.mode == 'expiration' ? {
                username: this.currentUsername,
                password: this.input.password
            } : this.input
            if (this.$refs.form.validate()) {
                this.loader = true

                service_user.login(payload).then(async (data) => {
                    if (data.error == 0) {
                        let userInfo = jwt_decode.decode(data.extra.token)
                        this.setLoginData(userInfo).then(async () => {
                            service_user.getUserDetails(userInfo[model_user.ID]).then(data => {
                                service_user.checkAndUpdateDefaultProps(data.config).then(() => {
                                    setTimeout(() => {
                                        window.location.reload()
                                    }, 500)
                                })
                            })
                        })
                    } else {
                        this.loader = false
                    }
                })
            } else {
                this.loader = false
            }
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/styles/resources/colors";

.cb-login-form-container {
  position: absolute;
  top: 28%;
  z-index: 5;
  border: solid 2px $color_app_contrast_medium;
}

.cb-login-form fieldset {
  border-color: $color_app_contrast_high !important;
  background-color: $color_app_contrast_low;
}

.cb-login-col-b .title {
  font-size: 30px !important;
  color: $color_app_bg_clear;
}

.cb-environment span {
  font-size: 25px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
</style>
