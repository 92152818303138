<template>
  <v-expand-x-transition>
    <v-snackbar
      v-if="alertData"
      v-model="alertOpen"
      :class="'pa-0 cb-dialog-alert cb-dialog-alert-' + alertData.status"
      :timeout="-1"
      bottom
      elevation="4"
      left
      outlined
      tile
    >
      <v-card class="cts-h-100 pt-1">
        <v-card-text class="pa-0 pt-1">
          <v-row class="justify-space-between pl-8 ">
            <span class="my-auto"> {{ alertData.message }}</span>

            <v-icon
              v-if="alertData.status ==0"
              class="mr-2"
              color="success"
            >check_circle
            </v-icon>
            <v-icon
              v-else-if="alertData.status ==2"
              class="mr-2"
              color="warning"
            >warning
            </v-icon>
            <v-icon
              v-else
              class="mr-2"
              color="error"
            >warning
            </v-icon>
          </v-row>
        </v-card-text>
      </v-card>
    </v-snackbar>
  </v-expand-x-transition>
</template>

<script>
import vuex_global from '@/store/modules/sections/vuex_global'
import store from '@/store'

export default {
  name: 'DialogMessage',
  data() {
    return {
      alertOpen: true,
      timer: null,
      snackTimeout: 5000,
    }
  },
  computed: {
    alertData() {
      this.closeAlert()
      return this.$store.state[vuex_global.constants.NAMESPACE][
        vuex_global.constants.S_ALERT_DIALOG_DATA
        ]
    },
  },
  methods: {
    closeAlert() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        store.dispatch(
          vuex_global.constants.NAMESPACE +
          '/' +
          vuex_global.constants.A_SET_ALERT_DIALOG_DATA,
          null
        )
      }, this.snackTimeout)
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/styles/resources/colors";

.cb-dialog-alert .v-snack__wrapper:before {
  background-color: $color_app_accent !important;
}

.cb-dialog-alert .v-snack__wrapper {
  height: 60px;
  font-weight: bolder;
  border: none;
  left: 200px;
}

.cb-dialog-alert i {
  font-size: 40px !important;
  margin-left: 20px;
}

.cb-dialog-alert .v-alert {
}

.cb-dialog-alert .v-snack__content {
  height: 100%;
}

.cb-dialog-alert .v-snack__action {
  display: none;
}

.cb-dialog-alert .v-alert__border {
  opacity: 1;
  border-color: $color_app_primary;
}

.cb-dialog-alert .v-snack__content {
  padding: 0;
}

.cb-dialog-alert-0 .v-snack__wrapper {
  border-left: solid 16px $color_app_success !important;
  color: $color_app_bg_dark !important;
}

.cb-dialog-alert-1 .v-snack__wrapper {
  border-left: solid 16px $color_app_error !important;
  color: $color_app_bg_dark !important;
}

.cb-dialog-alert-2 .v-snack__wrapper {
  border-left: solid 16px $color_app_warning !important;
  color: $color_app_bg_dark !important;
}
</style>
