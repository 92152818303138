<template>
  <v-row
    class="ma-0 justify-end cb-card-col-container cts-w-100"
    dense
  >
    <div
      v-for="(element,index) in elements"
      :key="index"
      class="d-inline-block pr-2"
    >
      <menu-model-b
        v-if="element.type == 'menu'"
        :actions="element.actions"
        :text="element.title"
        @actionLaunched="actionLaunched"
      >

      </menu-model-b>
      <button-with-icon-model-a
        v-else
        :color="element.color"
        :disabled="!element.enabled "
        :icon="element.icon"
        :loading="element.loading"
        :text="element.title"
        :title="element.enabled ? 'Descargar '+element.title.toLowerCase() : 'No disponible'"
        @click="clicked(element.id)"

      />
    </div>

  </v-row>
</template>

<script>
import MenuModelB from '@/components/repository/buttons/MenuModelB'
import ButtonWithIconModelA from '@/components/repository/buttons/ButtonWithIconModelA'

export default {
  name: 'CardMultipleCol',
  components: { ButtonWithIconModelA, MenuModelB },
  props: {
    elements: Array
  },
  methods: {
    clicked(event) {
      this.$emit('rightEntityClick', event)
    },
    actionLaunched(event) {
      this.$emit('rightEntityClick', event)
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/styles/resources/colors";
@import "../../../assets/styles/resources/variables";


.cb-card-col-container .cb-card-col-container-value {
  font-size: 18px !important;
  line-height: 18px !important;
  font-weight: bolder;
}

.cb-card-col-container .cb-card-col-container-title {
  padding-top: 5px;
  font-size: 14px !important;
  line-height: 14px !important;
  font-weight: 400;
  text-transform: uppercase;

}

.cb-card-col-container .cb-card-col-container-icon {
  font-size: $icon-size-4;
}

</style>
