<template>
		<v-col class="pa-0 cb-explorer-header">
				<v-row v-if="title != null">
						<v-col
							:class="helpers.nullOrEmpty(subtitle) ? 'my-3':'mb-1'"
							:cols="rightEntities != null ? 8:12"
							class="pa-0 pl-2"
						>

								<v-row
									class="ma-0 mt-1 mb-1"
									dense
								>
										<h1
											v-if="title"
											id="explorer-header-title"
											:title="title"
											class="my-auto cb-view-title text-truncate"
											v-html="title"
										/>
										<slot
											v-else
											name="title"
										/>
								</v-row>
								<v-row
									v-if="subtitle"
									class="ma-0"
									dense
									style="padding-left: 10px;"
								>
										<span class="cb-text-descriptor" v-html="subtitle"/>
										<v-icon
											v-if="subtitle_link"
											:title="'Ir a '+ subtitle"
											class="cb-cell-open-in-new ml-2"
											color="primary"
											x-small
											@click="$emit('subtitle_link')"
										>
												open_in_new
										</v-icon>
								</v-row>
						</v-col>

						<v-col
							v-if="rightEntities != null"
							class="my-auto text-right"
							cols="4"
						>
								<v-row
									v-if="rightEntitiesVisible"
									class="pr-2"
									justify="end"
								>
										<v-btn
											v-for="(ent,index) in rightEntities" :key="index" :color="ent.color ? ent.color : 'primary'"
											:disabled="!ent.enabled"
											class="mx-1"
											depressed
											small @click="entityClick(ent.id)">
												<v-icon class="mr-1" small>{{ent.icon}}</v-icon>
												{{ent.title}}
										</v-btn>
								</v-row>
								<v-row
									v-else
									class="pr-2"
									justify="end"
								>
										<card-multiple-col
											:class="!compact ? 'd-lg-block':''"
											:elements="rightEntities"
											class="d-none"
											@rightEntityClick="entityClick"
										/>
										<div :class="!compact ? 'd-lg-none':''">
												<MenuModelA
													:actions="rightEntities"
													text="Acciones"
													@actionLaunched="entityClick"
												/>

										</div>
								</v-row>
						</v-col>
				</v-row>
				<TabsHorizontalModelA
					v-if="sections"
					:tab-selected="tabSection"
					:tabs="sections"
					@changeTab="changeSection"
				/>
				<v-divider v-else></v-divider>
		</v-col>
</template>

<script>

import helpers from '@/plugins/helpers'
import CardMultipleCol from '@/components/repository/cards/CardMultipleCol'
import TabsHorizontalModelA from '@/components/repository/TabsHorizontalModelA'
import MenuModelA from '@/components/repository/buttons/MenuModelA'

/**
 * Cabecera de exploradores con gestión las pestañas
 */
export default {
		name: 'ExplorerHeader',
		components: {MenuModelA, TabsHorizontalModelA, CardMultipleCol},
		props: {
				/**  Título (Admite HTML) */
				title: {
						type: String,
						default: null
				},
				compact: {
						type: Boolean,
						default: false
				},
				/** Subtítulo (Admite HTML) */
				subtitle: {
						type: String,
						default: ''
				},
				/** Activa el enlace en el subtítulo */
				subtitle_link: {
						type: Boolean,
						default: false
				},
				/** Entidades de la parte derecha del título */
				rightEntities: {
						type: Array,
						default: null
				},
				rightEntitiesVisible: {
						type: Boolean,
						default: false
				},
				/** Tabs/Secciones [{name:'test_name',id:'test_id',error: false}]*/
				sections: {
						type: Array,
						default: null
				},
				entities: {
						type: Array,
						default: null
				},
				/** Código de la tab/sección activa */
				section: String,
				/** Desactiva la actualización de la query con los cambios de pestaña */
				disableQueryUpdate: {
						type: Boolean,
						default: false
				}
		},
		data() {
				return {
						tabSection: this.section,
						helpers
				}
		},
		watch: {
				section(val) {
						if (this.tabSection != val) {
								this.tabSection = val
						}
				},
				tabSection(val) {
						this.changeSection(val)
				}
		},
		created() {
				let querySection = this.$route.query.section
				if (helpers.nullOrEmpty(querySection) && !helpers.nullOrEmpty(this.sections)) {
						this.tabSection = this.sections[0].code
				} else {
						this.tabSection = querySection
				}
		},
		methods: {
				/**
				 * Abre el acceso directo de la entidad, llamado cuando se hace click en una entidad
				 * @param {string} route
				 * @param {object} params
				 * @param {object} query
				 * @public
				 */
				openEntityRoute(route, params, query) {
						helpers.redirect(route, params, query)
				},
				entityClick(data) {
						this.$emit('rightEntityClick', data)
				},
				changeSection(newSection) {
						if (!this.disableQueryUpdate && newSection != this.$route.query.section) {
								let data = {
										id: this.$route.query.id,
										name: this.$route.query.name
								}
								data.section = newSection


								Object.keys(this.$route.query).forEach(qk => {
										if (data[qk] == null) {
												data[qk] = this.$route.query[qk]
										}
								})

								this.$router.replace({query: data})
						}
						this.$emit(this.$events.EXPLORER_CHANGE_SECTION, newSection)
				}
		}
}
</script>

<style lang="scss">
@import "../../../assets/styles/resources/colors";
@import "../../../assets/styles/resources/variables";


.cb-header-title .v-icon {
		font-size: 45px !important;
}

.cb-header-title .title {
		font-size: $font-size-2 !important;
}


.cb-header-title .v-list-item__icon {
		margin: 0;
		margin-left: 15px !important;
		margin-right: 15px !important;
}

.cb-header-title .v-list-item__content {
		padding: 0;
}

.cb-entity {
		display: inline-block;
		margin-right: 10px;
		padding: 0 10px !important;
}

.cb-entity .cb-entity-link {
		font-weight: bold;
		color: $color_app_primary;
		margin-left: 4px;
}

.cb-entity:last-child {
		border-right: none !important;
}

.cb-entity-title {
		margin-right: 10px;
		color: $color_app_contrast_medium;
}

.cb-entity:hover .cb-entity-title {
		display: inline-block !important;
}

.cb-explorer-header {
		background-color: $color_app_clear;
}


</style>
<docs>

Uso básico del componente
```vue
<ExplorerHeader
	:sections="[{name:'overview',code:'overview'},{name:'test1',code:'test2'}]"
	section="overview"
	subtitle="Subtítulo con <strong>HTML</strong>"
	title="Título de ejemplo"
/>
```
Uso con entidades las cuales cuentan con enlace a otra página
```vue
<ExplorerHeader
	:entities="[{
              tooltip: 'Proceso',
              code: 'PSCCC',
              name: 'Proceso XXX',
              route: 'processes',
              param_key: 'code',
              param_value: 'xxxxx'
            }]"
	:sections="[{name:'overview',code:'overview'},{name:'test1',code:'test2'}]"
	section="overview"
	subtitle="Subtítulo con <strong>HTML</strong>"
	title="Título de ejemplo"
/>
```
</docs>
