<template>
  <v-container class="px-2 cb-view-container-without-tabs">
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-row style="height: 100%">
            <v-col
              :cols="selectedFile ? 6:9"
              class="pr-2 left-col"
              style="position: relative"
              @dragenter="showDropbox = true"
            >
              <div
                v-if="showDropbox"
                class="cb-dropzone"
                @dragleave="showDropbox=false"
              >
                <FileUploadArea
                  @click="handleUploadAreaClick"
                  @onDrop="handleOnDrop"
                >
                  <div>
                    <v-row
                      align="center"
                      class="d-flex flex-column"
                      dense
                      justify="center"
                    >
                      <v-icon
                        class="mt-5"
                        size="60"
                      >cloud_upload
                      </v-icon>
                      <p>
                        Click o arrastra fichero(s).
                      </p>
                    </v-row>
                  </div>
                </FileUploadArea>
              </div>
              <v-row
                v-else
              >
                <v-col class="pa-0">
                  <v-row class="my-1">
                    <v-col
                      class="my-auto"
                      cols="10"
                    >
                      <v-row>
                        <div class="mr-2">
                          <v-btn
                            color="primary"
                            icon
                            title="Volver"
                            @click="goBack"
                          >
                            <v-icon
                              :disabled="currentRelativePath == '//'"
                              class="mr-2"
                            >arrow_back
                            </v-icon>
                          </v-btn>
                          <v-btn
                            color="primary"
                            icon
                            title="Inicio"
                            @click="handleHomeClick"
                          >
                            <v-icon
                            >home
                            </v-icon>
                          </v-btn>
                        </div>
                        <v-text-field
                          :value="currentRelativePath.replace('///', '//')"
                          class="my-auto"
                          full-width
                          hide-details
                          outlined
                          readonly
                        ></v-text-field>
                      </v-row>
                    </v-col>

                    <v-col
                      class="my-auto"
                      cols="2"
                    >
                      <v-row
                        justify="end"
                      >
                        <v-btn
                          color="primary"
                          icon
                          title="Subir fichero"
                          @click="handleActions('upload_files')"
                        >
                          <v-icon
                          >upload
                          </v-icon>

                        </v-btn>
                        <v-btn
                          color="primary"
                          icon
                          title="Crear nuevo directorio"
                          @click="handleActions('create_new_folder')"
                        >
                          <v-icon
                          >create_new_folder
                          </v-icon>

                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row
                    class="my-1 cb-multifile-row cts-w-100"
                    justify="space-between"
                  >
                    <v-col class="pa-0">
                      <v-row
                        v-if="selectedItems != null && selectedItems.length != 0"
                      >
                        <v-col class="my-auto">
                          <v-row>
                            <v-btn
                              icon
                              title="Vaciar selección"
                              @click="selectedItems = []"
                            >
                              <v-icon
                                class="mr-2 "
                                color="primary"
                              >clear_all
                              </v-icon>
                            </v-btn>
                            <span class="ml-2 my-auto">
                          <strong class="mr-1">{{
                              selectedItems.length
                            }}</strong> {{ selectedItems.length == 1 ? 'recurso' : 'recursos' }} seleccionados
                        </span>
                          </v-row>
                        </v-col>
                        <v-col class="text-right">
                          <v-btn
                            :disabled="!selectedItems || selectedItems.length == 0"
                            icon
                            @click="downloadFileResource('all')"
                          >
                            <v-icon
                              class="pr-2"
                              color="primary"
                              title="Descargar todos (de uno en uno)"
                            >download
                            </v-icon>
                          </v-btn>
                          <v-btn
                            :disabled="!selectedItems || selectedItems.length == 0"
                            icon
                            @click="confirmDelete('all')"
                          >
                            <v-icon
                              class=""
                              color="error"
                              title="Borrar todos"
                            >delete
                            </v-icon>

                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row
                        v-else
                        class="py-1"
                      >
                        <v-btn icon>
                          <v-icon class="mr-2 resource-table-row__action">info</v-icon>
                        </v-btn>
                        <v-col
                          class="my-auto"
                        >
                          <span><strong>{{ resources.filter(r => r.type == 'folder').length }}</strong> carpetas</span>
                          <span class="mx-2 cb-text-descriptor">|</span>
                          <span><strong>{{ resources.filter(r => r.type == 'file').length }}</strong> ficheros</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-2"/>
                  <v-row class="scrollable-list">
                    <v-data-table
                      v-model="selectedItems"
                      :headers="tableHeaders"
                      :items="computedResources"
                      :single-select="false"
                      class="resource-table"
                      disable-pagination
                      hide-default-footer
                      hide-default-header
                      item-key="name"
                      show-select
                    >
                      <template v-slot:item.name="{ item }">
                        <v-row
                          ref="resource_row"
                          class="resource-row"
                          dense
                          justify="space-between"
                          @mouseleave="hoveredRow=null"
                          @mouseover="hoveredRow=item"
                        >
                          <v-col
                            class="my-auto"
                            cols="1"
                            @click="handleResourceClick(item)"
                          >
                            <v-icon v-if="item.type == 'folder'">folder</v-icon>
                            <v-icon v-else>description</v-icon>
                          </v-col>

                          <v-col
                            class="my-auto text-truncate"
                            cols="9"
                            @click="item.type == 'folder' ? handleResourceClick(item):null"
                          >
                            <v-row justify="space-between">
                            <span>
                              <span>{{ item.name }}
                              <span
                                v-if="item.type =='file'"
                                class="ml-1 cb-text-descriptor"
                              >
                                ({{ helpers.formatBytes(item.size) }})
                              </span></span>
                            </span>
                              <span v-if="item.type =='file'">
                                {{ item.last_updated | moment('DD/MM/YY HH:mm:ss') }}
                              </span>
                            </v-row>
                          </v-col>

                          <v-col class="text-right py-1 my-auto">
                            <div v-if="item == hoveredRow">
                              <v-btn
                                v-if="item.type == 'file'"
                                :title="'Vista previa de ' + item.name"
                                icon
                                small
                                @click="handleResourceClick(item)"
                              >
                                <v-icon
                                  color="primary"
                                  small
                                >visibility
                                </v-icon>
                              </v-btn>
                              <v-btn
                                v-if="item.type == 'file' && selectedItems.length == 0"
                                :title="'Descargar ' + item.name"
                                icon
                                small
                                @click="downloadFileResource(item)"
                              >
                                <v-icon
                                  color="primary"
                                  small
                                >download
                                </v-icon>
                              </v-btn>
                              <v-btn
                                v-if="selectedItems.length == 0"
                                icon
                                small
                                @click="confirmDelete(item)"
                              >
                                <v-icon
                                  color="error"
                                  small
                                >delete
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-col>

                        </v-row>

                      </template>

                    </v-data-table>

                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <!-- Columna derecha -->
            <v-col
              :cols="selectedFile ? 6:3"
              class="py-0 pl-2"
            >
              <v-row
                class="mt-3"
                justify="space-between"
              >
                <h1 class="my-auto">Vista previa de ficheros</h1>
                <v-btn
                  v-if="selectedFile"
                  icon
                  @click="file = null"
                >
                  <v-icon color="primary">clear</v-icon>
                </v-btn>
              </v-row>
              <v-divider class="my-2"/>
              <v-row
                v-if="selectedFile"
                style="max-height: 74vh;overflow-y: auto"
              >
                <FileViewer
                  :file="selectedFile"
                />
              </v-row>
              <v-row
                v-else
                class="pt-4"
                justify="center"
              >
                <p>Sin fichero seleccionado</p>
              </v-row>
            </v-col>

          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <NewResourceDialog
      v-if="dialogOpen"
      :extra="currentResource == null ? null : Array.isArray(currentResource) ? currentResource.map(cr => cr.name).join('<br> ') : currentResource"
      :mode="dialogAction"
      @close="dialogState(false)"
      @confirm_delete="handleConfirmation"
      @create_new_folder="createFolder"
      @upload_files="upload_files"
    />

  </v-container>
</template>

<script>
import { getResources, resourceAction } from '@/services/service_resource_manager'
import helpers, { downloadFile } from '@/plugins/helpers'
import NewResourceDialog from '@/components/protocol/resource_manager/NewResourceDialog.vue'
import FileViewer from '@/components/protocol/resource_manager/FileViewer.vue'
import FileUploadArea from '@/components/repository/FileUploadArea.vue'

export default {
  name: 'ResourceManager',

  components: { NewResourceDialog, FileUploadArea, FileViewer },

  data() {
    return {
      helpers,
      resources: [],
      relativePath: '//',
      dialogOpen: false,
      dialogAction: null,
      actionBack: false,
      loading: false,
      selectedResource: null,
      showDropbox: false,

      selectedItems: [],
      hoveredRow: null,
      file: null,

      tableHeaders: [
        { text: 'Name', value: 'name', align: 'start' },
      ]
    }
  },

  computed: {
    currentRelativePath() {
      return this.relativePath
    },

    computedResources() {
      return this.resources
    },

    currentResource() {
      return this.selectedResource
    },
    selectedFile() {
      return this.file
    }
  },

  created() {
    if (this.$route.query.path) {
      this.relativePath = this.$route.query.path
      this.listPath(this.relativePath)
    } else {
      this.addPathToURL('/')
      this.listPath('//')
    }
  },

  methods: {
    listPath(path) {
      this.selectedItems = []
      this.loading = true

      getResources(path).then(response => {
        this.parentPath = path

        if (response.data.extra.resources) {
          let res = response.data.extra.resources
          res = res.sort((a, b) => {
            if (a.type > b.type) {
              return -1
            }

            if (a.type < b.type) {
              return 1
            }

            return 0
          })

          this.resources = res

        } else {
          this.resources = []
        }
        this.loading = false
      })


    },

    handleResourceClick(resource) {
      if (resource.type == 'folder') {
        this.file = null
        this.relativePath = resource.relative_path

        this.addPathToURL(this.relativePath)
        this.listPath(resource.relative_path)

      } else {
        this.selectedResource = resource
        resourceAction('download_file', resource.relative_path).then(response => {
          this.file = {
            fileContent: response.data.filecontent,
            fileName: response.data.filename,
            fileType: response.data.filetype
          }
        })
      }
    },

    goBack() {
      this.selectedItems = []
      const p = `//${this.relativePath.replace('//', '').split('/').slice(0, this.relativePath.replace('//', '').split('/').length - 1).join('/')}`

      getResources(p).then(response => {
        this.relativePath = p
        this.addPathToURL(this.relativePath)
        this.resources = response.data.extra.resources

      })
    },

    downloadFileResource(file) {
      if (file == 'all') {
        this.selectedItems.forEach(f => {
          resourceAction('download_file', f.relative_path).then(response => {
            downloadFile({
              fileContent: response.data.filecontent,
              fileName: response.data.filename,
              fileType: response.data.filetype
            })
          })
        })

      } else {
        resourceAction('download_file', file.relative_path).then(response => {
          downloadFile({
            fileContent: response.data.filecontent,
            fileName: response.data.filename,
            fileType: response.data.filetype
          })
        })
      }
    },

    dialogState(open, action) {
      this.dialogOpen = open

      if (action) {
        this.dialogAction = action
      }
    },

    createFolder(name) {
      resourceAction('create_folder', `${this.relativePath}/${name}`).then(() => {
        this.listPath(this.relativePath)
      })

      this.dialogState(false)
    },

    upload_files(files) {
      resourceAction('upload_files', this.relativePath, files).then(() => {
        this.listPath(this.relativePath)
      })
    },

    deleteResource(resource) {
      const p = `//${resource.relative_path.replace('//', '').split('/').slice(0, resource.relative_path.replace('//', '').split('/').length - 1).join('/')}`

      if (resource.type == 'folder') {
        resourceAction('delete_folder', resource.relative_path).then(() => {
          this.listPath(p)
          this.dialogState(false)
        })

      } else {
        resourceAction('delete_file', resource.relative_path).then(() => {
          this.listPath(p)
          this.dialogState(false)
        })
      }
    },

    handleActions(action) {
      this.file = null
      this.dialogState(true, action)
    },

    handleConfirmation() {
      if (Array.isArray(this.currentResource)) {
        this.multipleDelete(this.currentResource)
      } else {
        this.deleteResource(this.currentResource)
      }
      this.selectedResource = null
      this.selectedItems = []
    },

    confirmDelete(res) {
      this.selectedResource = this.selectedItems.length > 0 ? this.selectedItems : res
      this.dialogState(true, 'confirm_delete')
    },

    handleOnDrop(files) {
      this.showDropbox = false
      this.upload_files(files)
    },

    handleUploadAreaClick() {
      this.handleActions('upload_files')
    },

    multipleDelete(files) {
      files.forEach(file => {
        this.deleteResource(file)
      })
    },

    handleHomeClick() {
      this.file = null
      this.relativePath = '//'
      this.addPathToURL(this.relativePath)
      this.listPath('//')
    },

    addPathToURL(path) {
      history.pushState({}, null, `${this.$route.path}?path=${path.replace(/\/\//g, '/')}`)
    },

    copyResourceURLToClipboard(resource) {
      helpers.copyCellVal(this.$refs.resource_row, `${location.toString()}?path=${resource.relative_path.replace(/\/\//g, '/')}`)
    }

  }
}
</script>

<style lang="scss">
@import "@/assets/styles/resources/colors";

.resource-row {
  height: 36px !important;
}

.resource-row:hover {
  cursor: pointer;
}

.resource-table tr td:first-child {
  width: 50px;
}

.scrollable-list {
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-bottom: 100px;
}

.left-col {
  border-right: solid 2px $color_app_contrast_low;
}

.cb-multifile-row {
  position: absolute;
  bottom: 0;
  background-color: $color_app_clear;
  z-index: 1;
  width: 50%;
  border-top: 1px solid $color_app_contrast_medium;
}


.cb-dropzone {
  z-index: 1;
  height: calc(100vh - 160px);
}
</style>
