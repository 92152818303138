import model_process from '@/constants/models/model_process'
import user_config from '@/constants/user_config'

export default {
    /* SOLO 1 NIVEL */
    [user_config.constants.GLOBAL]: {
        [user_config.constants.COMPACT_MODE]: false,
    },
    [user_config.constants.PROCESSES]: {
        [user_config.constants.TABLE_COLS]: [model_process.CODE, model_process.MASTER_ACCOUNT_CODE, model_process.SUB_ACCOUNT_CODE, model_process.PROJECT_CODE],
        [user_config.constants.TABLE_RPP]: 20,
        [user_config.constants.TABLE_DATES]: user_config.values[user_config.constants.TABLE_DATES][user_config.constants.DATE_ABSOLUTE],
        [user_config.constants.TABLE_ENTITIES]: user_config.values[user_config.constants.TABLE_ENTITIES][user_config.constants.ENTITY_CODE],
        [user_config.constants.FAV_PROCESSES]: []
    },
    [user_config.constants.INSTANCES]: {
        [user_config.constants.INSTANCES_SAVED_SEARCH]: [],
        [user_config.constants.TABLE_COLS]: [],
        [user_config.constants.TABLE_RPP]: 20,
        [user_config.constants.TABLE_DATES]: user_config.values[user_config.constants.TABLE_DATES][user_config.constants.DATE_ABSOLUTE],
        [user_config.constants.TABLE_ENTITIES]: user_config.values[user_config.constants.TABLE_ENTITIES][user_config.constants.ENTITY_CODE]
    },
    [user_config.constants.TEMPLATES]: {
        [user_config.constants.TABLE_COLS]: [],
        [user_config.constants.TABLE_RPP]: 20
    },
    [user_config.constants.BILLING_UNITS]: {
        [user_config.constants.TABLE_COLS]: [],
        [user_config.constants.TABLE_RPP]: 20
    },
    [user_config.constants.HOME]: {
        [user_config.constants.HOME_VIEW_ACTIVE]: 'default',
        [user_config.constants.HOME_ACTIVE_COMPONENTS]: [],
        [user_config.constants.HOME_LAYOUT]: [
            {'x': 0, 'y': 0, 'w': 6, 'h': 2, 'i': 'alertSection', 'minH': 3, 'minW': 7, 'maxH': 3},
            {'x': 0, 'y': 2, 'w': 12, 'h': 14, 'i': 'instancesMonitoring', 'minH': 14},
            {'x': 0, 'y': 16, 'w': 6, 'h': 4, 'i': 'instanceSearch', 'minH': 2},
            {'x': 6, 'y': 20, 'w': 6, 'h': 4, 'i': 'processesMonitoring', 'minH': 2}
        ]
    }
}
