<template>
    <v-btn
      :disabled="disabled"
      :title="text"
      class="cb-button-text-b"
      color="primary"
      depressed
      primary
      @click="$emit('click')"
    >
        <v-icon>{{icon}}</v-icon>
        {{text}}
    </v-btn>
</template>

<script>
export default {
    name: 'ButtonIconTextModelB',
    props: {
        text: String,
        icon: String,
        disabled: {type: Boolean, default: false}
    }
}
</script>

<style lang="scss">
.cb-button-text-b {
    height: 28px !important;
    width: 120px !important;
    min-width: 120px !important;

    & i {
        margin-right: 4px !important;
    }

    transition: width 1s ease !important;

}

</style>
