<template>
    <v-container class="pa-0">
        <v-row
          class="cb-table-options"
        >
            <v-col v-if="mode=='readonly_bar'">
                <span>Filtros | </span>
                <span
                  v-for="(filter,key) in responseFilters"
                  :key="key"
                  class="mr-2"
                >
        <span v-html="$vuetify.lang.t('$vuetify.' + key)"/>:
        <span
          class="font-weight-medium"
          v-html="checkValue(filter,key)"
        />
      </span>
            </v-col>
            <v-col v-else-if="mode=='readonly_rows'">
                <v-row
                  v-for="(filter,key) in responseFilters"
                  :key="key"
                >
        <span
          class=""
          v-html="$vuetify.lang.t('$vuetify.' + key) "
        />:
                    <span
                      class="font-weight-medium text-truncate ml-2"
                      style="max-width: 60%"
                      v-html="checkValue(filter,key)"
                    />
                </v-row>

            </v-col>
            <v-col
              v-else
              class="pa-0 mb-2"
            >
                <v-row>
                    <v-col
                      class="pa-0"
                      cols="11"
                    >
                        <v-row>
                            <div
                              v-if="search"
                              class="app-data-table-search-box"
                            >
                                <v-text-field
                                  v-model="tableSearch"
                                  :placeholder="'Buscar ' + model"
                                  class="ma-0"
                                  clearable
                                  dense
                                  hide-details
                                  @keypress.enter.stop="searchInTable()"
                                  @click:clear="searchInTable(true)"
                                />
                            </div>
                            <div class="mr-2">
                                <ButtonExpandable
                                  v-if="enableViewButton"
                                  :color="viewsOpened ? 'error':'primary'"
                                  :icon="viewsOpened ? 'visibility_off':'visibility'"
                                  :title="views.find(v => v.id == internalActiveView).text"
                                  class="mr-2"
                                  text="Vistas"
                                  @click="viewsClick"
                                />
                            </div>
                            <ButtonExpandable
                              v-if="enableFilterButton"
                              :color="filtersOpened ? 'error':'primary'"
                              :icon="filtersOpened ? 'filter_alt_off':'filter_alt'"
                              text="Filtros"
                              @click="filtersClick"
                            />
                            <v-col class="pa-0">
                                <v-chip
                                  v-for="(filter,key,index) in responseFilters"
                                  :key="index"
                                  :close="readOnly == null || !readOnly.includes(key)"
                                  class="app-filter-chip"
                                  color="primary"
                                  label
                                  @click:close="removeFilter(key)"
                                >
                                    <span class="mr-1">{{$vuetify.lang.t('$vuetify.' + key)}}:</span>
                                    <strong>{{filter | formatFilterValue}}</strong>
                                </v-chip>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                      class="pa-0"
                      cols="1"
                    >
                        <v-row justify="end">

                            <ButtonIconTextModelB
                              v-if="actions != null"
                              :icon="actionsOpened ? 'menu_open':'menu'"
                              text="Acciones"
                              @click="actionsClick"
                            />
                        </v-row>
                    </v-col>
                </v-row>
                <v-expand-transition>
                    <v-row
                      v-if="filtersOpened"
                    >
                        <v-col class="pa-0">
                            <slot name="filters"/>
                            <v-row
                              class="d-md-none"
                            >
                                <v-col cols="12">
                                    <button-icon-text-model-b
                                      :icon="$icons.I_CLOSE"
                                      text="Cerrar filtros"
                                      @click="filtersOpened=false"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-expand-transition>
                <v-expand-transition>
                    <v-row
                      v-show="actionsOpened"
                      class="app-actions-row mt-2"
                      justify="end"
                    >
                        <ButtonIconText
                          v-for="action in actions"
                          :key="action.id"
                          :icon="action.icon"
                          :text="action.text"
                          class="ml-2"
                          @click="actionClick(action.id)"
                        />
                    </v-row>
                </v-expand-transition>
                <v-expand-transition>

                    <v-row
                      v-show="viewsOpened"
                      class="app-actions-row mt-2 pl-0"
                    >
                        <span class="mr-2">Selección de vista </span>
                        <v-btn-toggle
                          v-model="internalActiveView"
                          mandatory
                        >
                            <v-btn
                              v-for="view in views"
                              :key="view.id"
                              :value="view.id"
                              active-class="cb-radio-button-active"
                              class="cb-radio-button"
                              small
                              @click="viewClick(view.id)">
                                <v-icon>{{view.icon}}</v-icon>
                                {{view.text}}
                            </v-btn>
                        </v-btn-toggle>
                    </v-row>
                </v-expand-transition>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import model_process from '@/constants/models/model_process'
import ButtonExpandable from '@/components/repository/buttons/ButtonIconTooltip'
import ButtonIconText from '@/components/repository/buttons/ButtonIconText'
import ButtonIconTextModelB from '@/components/repository/buttons/ButtonIconTextModelB'

export default {
    name: 'ExplorerFiltersRow',
    components: {ButtonIconTextModelB, ButtonIconText, ButtonExpandable},
    props: {
        mode: String,
        showVisualOptions: Boolean,
        activeView: String,
        actions: {
            type: Array,
            default: null
        },
        views: {
            type: Array,
            default: null
        },

        readOnly: {
            type: Array,
            default: null
        },
        responseFilters: {
            type: [Array, Object],
            default: null
        },
        search: {
            type: Boolean,
            default: false
        },
        model: String,

        enableFilterButton: {
            default: false,
            type: Boolean
        },
        enableViewButton: {
            default: false,
            type: Boolean
        },
    },
    data() {
        return {
            tableSearch: null,
            filtersOpened: false,
            viewsOpened: false,
            actionsOpened: false,
            internalActiveView: this.activeView
        }
    },
    computed: {
        favFilterActive() {
            return this.responseFilters == null || typeof this.responseFilters[model_process.CODE] == 'object'
        },
    },
    methods: {
        searchInTable(clear) {
            if (clear === true) {
                this.tableSearch = null
                this.$emit(this.$events.EXPLORER_SEARCH, this.tableSearch)
            }
            if (this.tableSearch != null) {
                this.$emit(this.$events.EXPLORER_SEARCH, this.tableSearch)
            }
        },
        filterFav() {
            this.$emit(this.$events.EXPLORER_FILTER_FAV, this.favFilterActive)
        },
        removeFilter(key) {
            if (key == 'OR') {
                this.$emit(this.$events.EXPLORER_SEARCH, null)
                this.tableSearch = null
            } else {
                this.$emit(this.$events.EXPLORER_REMOVE_FILTER, key)
            }
        },
        actionsClick() {
            this.actionsOpened = !this.actionsOpened
            if (this.filtersOpened) {
                this.filtersOpened = false
            }
        },
        actionClick(id) {
            this.actionsOpened = false
            this.$emit(this.$events.EXPLORER_ACTION_CLICKED, id)
        },
        viewClick(id) {
            this.$emit(this.$events.EXPLORER_VIEW_CLICKED, id)
        },
        filtersClick() {
            this.filtersOpened = !this.filtersOpened
            if (this.actionsOpened) {
                this.actionsOpened = false
            }
        },
        viewsClick() {
            this.viewsOpened = !this.viewsOpened
            if (this.actionsOpened) {
                this.actionsOpened = false
            }
        },
        checkValue(value, key) {
            if (Array.isArray(value) === true) {
                if (key == model_process.CODE) {
                    return 'Favoritos'
                }
                return value.join(', ')
            }
            if (typeof value == 'object') {
                if (value.from != null && value.to != null) {
                    return this.$moment.unix(value.from).format('DD/MM/YY HH:mm') + ' -> ' + this.$moment.unix(value.to).format('DD/MM/YY HH:mm')
                }
                return value[Object.keys(value)[0]]
            }
            if (typeof value == 'number' && (value === 0 || value === 1)) {
                return value ? 'Sí' : 'No'
            }

            return value

        }
    },
    filters: {
        formatFilterValue(filter) {

            if (Array.isArray(filter)) {
                return 'Múltiple'
            }
            if (typeof filter == 'object') {
                return filter[Object.keys(filter)[0]]
            }
            return filter
        }
    }
}
</script>

<style
  lang="scss"
>
@import "../../../assets/styles/resources/colors";
@import "../../../assets/styles/resources/variables";

.cb-table-options {
    font-size: $font-size-4 !important;
    justify-content: space-between;

    & .app-filter-chip {
        border-radius: 2px !important;
        height: 28px;
        margin: 0 8px;
    }
}

.cb-table-options span {
    font-size: $font-size-4 !important;
    margin-top: auto;
    margin-bottom: auto;
}

.cb-table-options .v-icon {
    font-size: $icon-size-4 !important;
    margin-right: 5px;
}


.cb-filters-container {
    overflow: auto;
    padding-left: 5px;
}

.app-data-table-search-box {
    width: 340px;
    max-width: 340px;
    margin-right: 8px !important;
    border: 1px solid $color_app_contrast_medium;
    height: 28px;
    background-color: $color_app_clear;
    border-radius: 2px;

    & .v-input {
        margin-top: -2px !important;
    }

    & .v-input__slot {
        padding-left: 8px !important;

    }

    & .v-input__slot:before {
        border-color: $color_app_contrast_medium !important;


    }
}

.app-filters-row {
    padding: 0 !important;

    & [class^="col-"] {
        padding-top: 6px;
    }
}

.app-actions-row {
    border-right: 2px solid $color_app_primary;
    padding: 4px 8px !important;
}

.cb-radio-button {
    width: auto !important;
    min-width: 140px !important;
    background-color: $color_app_contrast_low !important;
    background-color: $color_app_contrast_low !important;

    & .v-icon {
        color: $color_app_primary !important;
    }
}

.cb-radio-button-active {
    color: $color_app_contrast_high;
    background-color: $color_app_primary !important;

    & .v-icon {
        color: white !important;
    }
}
</style>
