<template>
  <v-row class="cb-title-model-a">
    <v-icon class="cb-icon">{{ icon }}</v-icon>
    <v-col>
      <span class="cb-title">{{ title }}</span>
    </v-col>
    <div>
      <slot name="save"></slot>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'TitleModelA',
  props: {
    title: String,
    icon: String,
  },
}
</script>

<style lang="scss">
@import "../../assets/styles/resources/variables";
@import "../../assets/styles/resources/colors";

.cb-title-model-a {
  border-bottom: 1px solid $color_dark;
  padding: $p4;
  position: sticky;
  z-index: 1;
  top: 0;
}

.cb-title-model-a .v-icon {
  width: 30px;
}

.cb-title-model-a .cb-title {
  font-size: $font-size-4 !important;
  font-weight: bolder !important;
  text-transform: uppercase;
}
</style>
